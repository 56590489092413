// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-projects-js": () => import("/opt/build/repo/src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-books-js": () => import("/opt/build/repo/src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-exhibitions-js": () => import("/opt/build/repo/src/templates/exhibitions.js" /* webpackChunkName: "component---src-templates-exhibitions-js" */),
  "component---src-templates-new-js": () => import("/opt/build/repo/src/templates/new.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("/opt/build/repo/src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-exhibitions-js": () => import("/opt/build/repo/src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-texts-js": () => import("/opt/build/repo/src/pages/texts.js" /* webpackChunkName: "component---src-pages-texts-js" */)
}

